import { useQuery } from '@apollo/client';
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import DocumentLayout from 'layouts/DocumentLayout';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import Divider from 'primitives/Divider';
import LoadingIndicator from 'primitives/LoadingIndicator';

import alIndiaHand from 'media/alIndiaHand.svg';
import alIndiaLogo from 'media/alIndiaLogo.png';

import { INDIAN_STATES } from 'utils/states';

import FundClosings from './FundClosings';

const INVESTMENTS_QUERY = gql(`
  query fetchUnitStatement($id: ID!, $cursor: ID, $limit: Int) {
    investmentEntity(id: $id) {
      id
      name
      user {
        id
        phoneNumber
        email
      }
      kyc {
        id
        address {
          id
          lineOne
          lineTwo
          city
          state
          country
          pincode
        }
      }
      closings(filters: { statuses: [ACTIVE] }, cursor: $cursor, limit: $limit) {
        nodes {
          id
          deal {
            id
            schemeName
            companyInformation {
              company {
                id
                image
                name
              }
            }
          }
          numberOfUnits
          setupFee
          amount
          drawdownAmount
          wiredAt
          statistics {
            netValue
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      distributions {
        id
        deal {
          id
          schemeName
          companyInformation {
            company {
              id
              image
              name
            }
          }
        }
        exitedAt
        investedAmount
      }
      fundDistributions {
        id
      }
    }
  }
`);

const UnitStatement: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch, fetchMore } = useQuery(INVESTMENTS_QUERY, {
    variables: {
      limit: 1000,
      id: investmentEntityId,
    },
    notifyOnNetworkStatusChange: true,
  });

  function renderContent() {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const investmentEntity = data.investmentEntity;
    const closings = data.investmentEntity.closings.nodes;
    const pageInfo = data.investmentEntity.closings.pageInfo;

    return (
      <>
        <div
          className="bg-indigo-700 rounded-xl mb-5 relative bg-no-repeat bg-[length:500px_500px] bg-[120%_5rem]"
          style={{ backgroundImage: `url(${alIndiaHand})` }}
        >
          <div className="min-w-0 flex-1 absolute left-4 top-4 bg-white p-1 rounded-md">
            <img className="h-4 w-auto" src={alIndiaLogo} alt="Your Company" />
          </div>
          <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Investor Unit
                <br />
                Holding Statement
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-indigo-200">
                {dayjs().format('D MMMM YYYY')}
              </p>
            </div>
          </div>
        </div>
        <dl className="my-5 space-y-4 text-base leading-7 text-gray-600 p-4 bg-white rounded-xl border border-gray-200">
          <p className="text-gray-900 text-xl font-bold">{investmentEntity.name}</p>
          <div className="flex gap-x-4">
            <dt className="flex-none">
              <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>
              {investmentEntity.kyc?.address?.lineOne}, {investmentEntity.kyc?.address?.lineTwo}
              <br />
              {investmentEntity.kyc?.address?.city},{' '}
              {INDIAN_STATES[investmentEntity.kyc?.address?.state || '']}{' '}
              {investmentEntity.kyc?.address?.pincode}
            </dd>
          </div>
          <div className="flex gap-x-4">
            <dt className="flex-none">
              <span className="sr-only">Telephone</span>
              <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>
              <a className="hover:text-gray-900" href="tel:+1 (555) 234-5678">
                {investmentEntity.user.phoneNumber}
              </a>
            </dd>
          </div>
          <div className="flex gap-x-4">
            <dt className="flex-none">
              <span className="sr-only">Email</span>
              <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
            </dt>
            <dd>
              <a className="hover:text-gray-900" href="mailto:hello@example.com">
                {investmentEntity.user.email}
              </a>
            </dd>
          </div>
        </dl>
        <h2 className="text-xl font-bold text-center">Syndicate Investments</h2>
        <p className="text-gray-600 text-sm mb-4 text-center">
          As of {dayjs().format('D MMMM YYYY')}
        </p>
        <DataTable
          data={closings}
          columns={[
            {
              label: 'Investee (DBA)',
              fieldName: 'deal.companyInformation.company',
              type: 'IMAGE_WITH_NAME',
            },
            {
              label: 'Scheme Name',
              fieldName: 'deal.schemeName',
            },
            {
              label: 'Investment Date',
              fieldName: 'wiredAt',
              type: 'DATE',
            },
            {
              label: 'Units Type',
              fieldName: 'deal.companyInformation.company.name',
            },
            {
              label: 'No. of Units',
              fieldName: 'numberOfUnits',
              type: 'NUMBER',
            },
            {
              label: 'Drawdown',
              fieldName: 'drawdownAmount',
              type: 'CURRENCY',
            },
            {
              label: 'Investment',
              fieldName: 'amount',
              type: 'CURRENCY',
            },
            {
              label: 'Setup fee',
              fieldName: 'setupFee',
              type: 'CURRENCY',
            },
            {
              label: 'Estimated FMV',
              fieldName: 'statistics.netValue',
              type: 'CURRENCY',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                filter: { status: 'ACTIVE' },
                cursor: pageInfo.cursor,
              },
            })
          }
        />

        {data.investmentEntity.distributions && data.investmentEntity.distributions.length > 0 && (
          <>
            <h2 className="text-xl font-bold text-center mt-8">Exited Syndicate Investments</h2>
            <p className="text-gray-600 text-sm mb-4 text-center">
              As of {dayjs().format('D MMMM YYYY')}
            </p>
            <DataTable
              data={data.investmentEntity.distributions}
              columns={[
                {
                  label: 'Investee (DBA)',
                  fieldName: 'deal.companyInformation.company',
                  type: 'IMAGE_WITH_NAME',
                },
                {
                  label: 'Scheme Name',
                  fieldName: 'deal.schemeName',
                },
                {
                  label: 'Investment Date',
                  fieldName: 'exitedAt',
                  type: 'DATE',
                },
                {
                  label: 'Units Type',
                  fieldName: 'deal.companyInformation.company.name',
                },
                {
                  label: 'No. of Units',
                  fieldName: 'numberOfUnits',
                  type: 'NUMBER',
                },
                {
                  label: 'Drawdown',
                  fieldName: 'drawdownAmount',
                  type: 'CURRENCY',
                },
                {
                  label: 'Investment',
                  fieldName: 'investedAmount',
                  type: 'CURRENCY',
                },
                {
                  label: 'Setup fee',
                  fieldName: 'setupFee',
                  type: 'CURRENCY',
                },
                {
                  label: 'Proceeds after carry earned/paid',
                  fieldName: 'totalAmountAfterCarry',
                  type: 'CURRENCY',
                },
                {
                  label: 'Amount distributable after TDS',
                  fieldName: 'distributedAmount',
                  type: 'CURRENCY',
                },
              ]}
            />
          </>
        )}

        <FundClosings investmentEntityId={investmentEntityId} />

        {data.investmentEntity.fundDistributions &&
          data.investmentEntity.fundDistributions.length > 0 && (
            <>
              <h2 className="text-xl font-bold text-center">Fund Distributions</h2>
              <p className="text-gray-600 text-sm mb-4 text-center">
                As of {dayjs().format('D MMMM YYYY')}
              </p>
              <DataTable
                data={data.investmentEntity.fundDistributions}
                columns={[
                  // {
                  //   label: 'Company',
                  //   fieldName: 'deal.companyInformation.company',
                  //   type: 'IMAGE_WITH_NAME',
                  // },
                  {
                    label: 'Fund Name',
                    fieldName: 'fund.name',
                  },
                  {
                    label: 'Investment Date',
                    fieldName: 'wiredAt',
                    type: 'DATE',
                  },
                  {
                    label: 'Units Type',
                    fieldName: 'deal.companyInformation.company.name',
                  },
                  {
                    label: 'No. of Units',
                    fieldName: 'numberOfUnits',
                    type: 'NUMBER',
                  },
                  {
                    label: 'Drawdown',
                    fieldName: 'drawdownAmount',
                    type: 'CURRENCY',
                  },
                  {
                    label: 'Investment',
                    fieldName: 'amount',
                    type: 'CURRENCY',
                  },
                  {
                    label: 'Setup fee',
                    fieldName: 'setupFee',
                    type: 'CURRENCY',
                  },
                  {
                    label: 'Estimated FMV',
                    fieldName: 'statistics.netValue',
                    type: 'CURRENCY',
                  },
                ]}
              />
            </>
          )}

        <div className="max-w-3xl mt-4 px-4">
          <ul className="list-decimal">
            <li className="text-xs text-gray-500">
              This is a system generated report, please bring any discrepancy/error to our attention
              by writing to us at india@angellist.com
            </li>
            <li className="text-xs text-gray-500">
              The estimated Fair Market Value (FMV) of an investment is based on the price at which
              a company issues securities in its most recent equity round.
            </li>
            <li className="text-xs text-gray-500">
              Scheme terms and conditions are as per the contribution agreement and the deal note
              shared at the time of making investment.
            </li>
            <li className="text-xs text-gray-500">
              FMV is before allocating carry to the Syndicate Lead and the Investment Manager
            </li>
            <li className="text-xs text-gray-500">
              The balance amount (if any) from your fund investments has been carried forward and
              will reflect in future statements.
            </li>
          </ul>
        </div>

        <Divider />
        <div className="text-gray-600 text-sm text-center">
          <h2 className="text-gray-800 text-lg font-bold">AngelList India LLP</h2>
          <p className="font-semibold">SEBI Registration No: IN/AIF1/17-18/0504</p>
          <p className="font-semibold">GST Registration No: 07AAGTA1571Q1ZC</p>
          <p>Mailing Address - C/o Vistra Corporate Services (India) Private Limited</p>
          <p>No. 52, 1st Floor, 100 Feet Road 2nd Block, Koramangala, Bangalore 560034, India</p>
          <p>TEL. NO.: +91 80-2563-0686</p>
        </div>
      </>
    );
  }

  return <DocumentLayout>{renderContent()}</DocumentLayout>;
};

export default UnitStatement;
