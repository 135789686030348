import { Disclosure } from '@headlessui/react';
import { BugAntIcon, DocumentTextIcon, EyeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { AcademicCapIcon, BanknotesIcon } from '@heroicons/react/24/outline';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { cloneElement, useState } from 'react';
import { FC } from 'react';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import SlideOver from 'primitives/SlideOver';

import ContactSupport from './ContactSupport';
import Disclosures from './Disclosures';
import { communityGuidelines, generalFaqs, investInSpvFaqs, ruvFaqs } from './FAQs';
import ReportIssue from './ReportIssue';
import Terms from './Terms';
import Valuation from './Valuation';

const actions = [
  {
    id: 'report-issue',
    title: 'Report an issue',
    subTitle:
      "Data discrepancy, bug report, feature request or general feedback. We're here to help you.",
    icon: <BugAntIcon />,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
  },
  {
    id: 'terms',
    title: 'Privacy and Terms',
    subTitle:
      'Information about the general terms of use, security, privacy, data policies on our platform.',
    icon: <DocumentTextIcon />,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    id: 'contact-support',
    title: 'Contact Support',
    subTitle:
      "We'd love to hear from you. Reach out to us for any queries or assistance in raising capital or investing.",
    icon: <PhoneIcon />,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
  },
  {
    id: 'disclosures',
    title: 'Legal and Regulatory Compliance',
    subTitle: 'AngelList India is regulated by SEBI and follows all legal and regulatory norms.',
    icon: <EyeIcon />,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
  },
  {
    id: 'tutorials',
    title: 'Tutorials and Resources',
    subTitle: 'Learn everything about angel investing, using the platform, tips and more.',
    href: 'https://learn.angellist.com/',
    icon: <AcademicCapIcon />,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
  },
  {
    id: 'valuation-policy',
    title: 'Valuation Policy',
    subTitle: 'Understand how we value startups, company updates and the methodology behind it.',
    icon: <BanknotesIcon />,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
  },
];

const faqHeaders = [
  {
    title: 'General',
    questions: generalFaqs,
  },
  {
    title: 'Investing in an SPV through AngelList India',
    questions: investInSpvFaqs,
  },
  {
    title: 'Submitting an RUV',
    questions: ruvFaqs,
  },
];

const Action: FC<{
  id: string;
  idx: number;
  title: string;
  subTitle: string;
  href?: string;
  onClick?: (content: any) => void;
  icon: any;
  iconForeground: string;
  iconBackground: string;
}> = ({ id, idx, title, subTitle, href, onClick, icon, iconForeground, iconBackground }) => (
  <div
    key={title}
    className={classNames(
      idx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
      idx === 1 ? 'sm:rounded-tr-lg' : '',
      idx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
      idx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
      'group relative bg-white p-6 hover:bg-gray-50 hover:cursor-pointer'
    )}
  >
    <div>
      <span
        className={classNames(
          iconBackground,
          iconForeground,
          'inline-flex rounded-lg p-3 ring-4 ring-white'
        )}
      >
        {cloneElement(icon, { className: 'h-6 w-6' })}
      </span>
    </div>
    <div className="mt-8">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          onClick={() => (onClick && !href ? onClick(id) : {})}
          className="focus:outline-none"
        >
          {/* Extend touch target to entire panel */}
          <span className="absolute inset-0" aria-hidden="true" />
          {title}
        </a>
      </h3>
      <p className="mt-2 text-sm text-gray-500">{subTitle}</p>
    </div>
    <span
      className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
      aria-hidden="true"
    >
      <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
      </svg>
    </span>
  </div>
);

const Help: FC = () => {
  const [contentOnDisplay, setContentOnDisplay] = useState('');

  function renderContent() {
    return (
      <>
        <SlideOver open={!!contentOnDisplay} onClose={() => setContentOnDisplay('')}>
          {contentOnDisplay === 'report-issue' && (
            <ReportIssue onClose={() => setContentOnDisplay('')} />
          )}
          {contentOnDisplay === 'contact-support' && (
            <ContactSupport onClose={() => setContentOnDisplay('')} />
          )}
          {contentOnDisplay === 'terms' && <Terms />}
          {contentOnDisplay === 'disclosures' && <Disclosures />}
          {contentOnDisplay === 'valuation-policy' && <Valuation />}
        </SlideOver>
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 ring-1 ring-gray-200 sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          {actions.map((action, actionIdx) => (
            <Action key={action.id} idx={actionIdx} {...action} onClick={setContentOnDisplay} />
          ))}
        </div>

        <div className="my-6 p-5 w-full divide-y divide-gray-900/10 bg-gray-50 rounded-xl">
          <h2 className="text-2xl font-semibold leading-10 tracking-tight text-gray-800">
            Frequently asked questions
          </h2>
          <dl className="mt-5 space-y-6 divide-y divide-gray-900/10">
            {faqHeaders.map(({ title, questions }) => (
              <Disclosure as="div" key={title} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-700">
                        <span
                          className={classNames(
                            'text-lg font-semibold leading-7',
                            open && 'underline'
                          )}
                        >
                          {title}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      {questions.map(faq => (
                        <Disclosure as="div" key={faq.question} className="pt-6">
                          {({ open }) => (
                            <>
                              <dt>
                                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-700">
                                  <span className="text-base font-semibold leading-7">
                                    {faq.question}
                                  </span>
                                  <span className="ml-6 flex h-7 items-center">
                                    {open ? (
                                      <MinusIcon className="h-6 w-6" aria-hidden="true" />
                                    ) : (
                                      <PlusIcon className="h-6 w-6" aria-hidden="true" />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </dt>
                              <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                <div className="text-base leading-7 text-gray-600">
                                  {faq.answer}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>

        <div className="mb-6 p-5 w-full divide-y divide-gray-900/10 bg-gray-50 rounded-xl">
          <h2 className="text-2xl font-semibold leading-10 tracking-tight text-gray-800">
            Community Guidelines
          </h2>
          <dl className="mt-5 space-y-6 divide-y divide-gray-900/10">
            <Disclosure as="div" key={communityGuidelines.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-700">
                      <span className="text-base font-semibold leading-7">
                        {communityGuidelines.question}
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <MinusIcon className="h-6 w-6" aria-hidden="true" />
                        ) : (
                          <PlusIcon className="h-6 w-6" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <div className="text-base leading-7 text-gray-600">
                      {communityGuidelines.answer}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </dl>
        </div>

        <div className="sm:rounded-lg border border-gray-200 mb-10">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-2xl font-bold tracking-tight text-gray-700 sm:text-3xl">
              How is <span className="text-orange-500"> AngelList India</span> different?
            </h2>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
              <div className="max-w-xl text-sm text-gray-400">
                <p className="text-lg tracking-tight text-gray-700 sm:text-xl max-w-xl">
                  The power of AngelList perfectly adapted for the Indian investment ecosystem.
                </p>
              </div>
              <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                <a
                  href="https://medium.com/@AngelListIndia/introducing-a-new-platform-for-india-854cc8b909a4"
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Help & Information"
        subTitle="Learn more about Angellist India"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default Help;
