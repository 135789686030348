import { NetworkStatus, useQuery } from '@apollo/client';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';
import { gql } from '__generated__/gql';
import { DealStatusEnumType, SyndicateTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterBar from 'components/FilterBar';
import ReportDataDiscrepancy from 'components/ReportDataDiscrepancy';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import debounce from 'utils/debounce';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

const DEALS_QUERY = gql(`
  query GetDealsOfSyndicate(
    $syndicateId: ID!
    $cursor: ID
    $limit: Int
    $filters: DealsFilterType
  ) {
    syndicate(id: $syndicateId) {
      id
      status
      type
      statistics {
        id
        dealStatisticsBreakdownByDealStatus {
          status
          numberOfDeals
        }
      }
      deals(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          companyInformation {
            id
            company {
              id
              name
              image
            }
          }
          dealTerms {
            id
            fundingRoundName
          }
          allocation
          statistics {
            id
            totalWiredAmount
          }
          closingDate
          status
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
          totalCount
        }
      }
    }
  }
`);

const Deals: FC = () => {
  const navigate = useNavigate();
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    DEALS_QUERY,
    {
      variables: {
        syndicateId,
        limit: 50,
        cursor: undefined,
        filters: {
          status: undefined,
          text: '',
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const syndicate = data.syndicate;
    const deals = syndicate.deals.nodes;
    const statistics = syndicate.statistics;
    const pageInfo = syndicate.deals.pageInfo;

    return (
      <>
        <div className="sm:flex sm:items-center justify-between">
          <FilterBar
            filters={[
              {
                label: 'All',
                value: undefined,
              },
              ...statistics.dealStatisticsBreakdownByDealStatus
                .filter(({ status }) =>
                  ['DRAFT', 'UNDER_REVIEW', 'RAISING', 'WIRED', 'CANCELLED', 'POOLING'].includes(
                    status as DealStatusEnumType
                  )
                )
                .map(({ status, numberOfDeals }) => ({
                  label: `${statusEnumToReadable(status)?.label} (${numberOfDeals})`,
                  value: status,
                })),
            ]}
            currentValue={variables?.filters?.status}
            onSelect={value => {
              refetch({
                filters: {
                  // @ts-ignore
                  status: value as DealStatusEnumType,
                },
              });
            }}
          />
          <Tooltip
            title={
              data?.syndicate.status === 'UNDER_REVIEW'
                ? 'Your syndicate is still under review'
                : ''
            }
          >
            <span>
              <Button
                disabled={data?.syndicate.status === 'UNDER_REVIEW'}
                onClick={() => navigate(`/syndicate-dashboard/${syndicateId}/deals/create`)}
                leadingIcon={<PlusIcon />}
              >
                {syndicate.type === SyndicateTypeEnumType.Ruv
                  ? 'Create New RUV'
                  : 'Create New Deal'}
              </Button>
            </span>
          </Tooltip>
        </div>
        <div className="flex justify-between items-end">
          <TextField
            placeholder="Search for a deal"
            onChange={debounce(e => {
              refetch({
                filters: {
                  ...variables?.filters,
                  text: e.target.value,
                },
              });
            }, 500)}
          />
          <span className="text-sm text-gray-500">
            {deals.length} of {pageInfo.totalCount} deals
          </span>
        </div>
        <DataTable
          data={deals}
          onClick={deal => {
            if (deal.status === 'UNDER_OPS_REVIEW') {
              navigate(`/syndicate-dashboard/${syndicateId}/deals/create/${deal.id}/under-review`);
            } else {
              navigate(`/syndicate-dashboard/${syndicateId}/deals/${deal.id}`);
            }
          }}
          columns={[
            {
              label: 'Company',
              fieldName: 'companyInformation.company',
              type: 'IMAGE_WITH_NAME',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Round Name',
              fieldName: 'dealTerms.fundingRoundName',
              type: 'FUNDING_ROUND',
            },
            {
              label: 'Allocation',
              fieldName: 'allocation',
              type: 'CURRENCY',
            },
            {
              label: 'Raised',
              fieldName: 'statistics.totalWiredAmount',
              type: 'CURRENCY',
            },
            {
              label: 'Closing Date',
              fieldName: 'closingDate',
              type: 'DATE',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATE',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
        <ReportDataDiscrepancy />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title={data?.syndicate.type === SyndicateTypeEnumType.Ruv ? 'Roll Up Vehicles' : 'Deals'}
        subTitle={`View past and current ${
          data?.syndicate.type === SyndicateTypeEnumType.Ruv
            ? 'RUVS of your company'
            : 'deals of your syndicate'
        }`}
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default Deals;
