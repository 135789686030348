const generalFaqs = [
  {
    question: "What is AngelList India's Investment Structure?",
    answer: (
      <div>
        <p>
          AngelList India is a SEBI-registered Angel Fund, a sub-category of VCF under Category-I
          AIF.
        </p>
        <p>Key characteristics include:</p>
        <ul className="list-disc pl-5 mt-2">
          <li>A separate special purpose vehicle (SPV) is created for each startup investment</li>
          <li>
            Investors receive <strong>'units'</strong> instead of direct company shares
          </li>
          <li>Investors invest through AngelList India's platform</li>
        </ul>
        <p className="mt-2">
          Unit Statements depicting portfolio company valuations are shared biannually (September
          and March).
        </p>
      </div>
    ),
  },
  {
    question: 'Who Can Invest in India?',
    answer: (
      <div>
        <p>As per SEBI AIF Regulations 2012, investors must be 'accredited':</p>
        <h3 className="mt-2 font-semibold">Individual Investor Criteria:</h3>
        <ul className="list-disc pl-5 mt-2">
          <li>
            Must have net tangible assets of at least INR 2 crore (excluding principal residence)
          </li>
          <li>
            Must meet one of these conditions:
            <ol className="list-decimal pl-5">
              <li>Has early-stage investment experience</li>
              <li>Has experience as a serial entrepreneur</li>
              <li>Is a senior management professional with at least 10 years of experience</li>
            </ol>
          </li>
        </ul>
        <h3 className="mt-2 font-semibold">Body Corporate Criteria:</h3>
        <ul className="list-disc pl-5 mt-2">
          <li>Minimum net worth of INR 10 crore</li>
          <li>
            Must provide an audited balance sheet or chartered accountant certificate for
            verification
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Can NRIs / International Investors Invest?',
    answer: (
      <div>
        <p>Investment terms for non-resident investors have been updated:</p>
        <ul className="list-disc pl-5 mt-2">
          <li>Previous minimum: INR 25 lakh per deal</li>
          <li>New minimum: INR 10 lakh per deal</li>
          <li>
            Conditions for new non-resident syndicate investors:
            <ul className="list-disc pl-5">
              <li>
                Must sign an undertaking promising compliance with Regulation 19D(3) of SEBI AIF
                regulations
              </li>
            </ul>
          </li>
          <li>
            Existing investors who have invested INR 25 lakh or more will automatically have the new
            minimum applied
          </li>
        </ul>
        <p className="mt-2 italic">
          This change aims to accommodate investors wanting to increase their India exposure.
        </p>
      </div>
    ),
  },
  {
    question: 'What is the Minimum Syndicate Size?',
    answer:
      'Syndicates are required to invest a minimum of INR 50 Lakh per company per transaction.',
  },
  {
    question: 'RUV Size and Configuration',
    answer: (
      <div>
        <ul className="list-disc pl-5">
          <li>Founders must source 100% of the capital from accredited investors</li>
          <li>Minimum RUV allocation in a fundraising round: INR 50 Lacs</li>
          <li>Maximum RUV investment into a company: INR 10 Crores</li>
          <li>
            Average minimum cheque size per Investor: INR 3 Lacs
            <p className="italic text-sm mt-1">
              (Up to 5 exceptions allowed for angels with cheques between INR 1 to 3 Lacs)
            </p>
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Pricing for RUVs',
    answer: (
      <div>
        <p>
          Effective April 1, 2024, a company raising capital through an RUV on the AngelList India
          platform will be required to pay a one-time setup cost.
        </p>
      </div>
    ),
  },
  {
    question: 'Can a Syndicate Raise More Capital Than the Allocation Set for the SPV?',
    answer: (
      <div>
        <p>
          When an SPV is set up, the <strong>syndicate lead ("Lead")</strong> or a Founder (in the
          case of a RUV) needs to indicate a target allocation.
        </p>
        <p className="mt-2">
          However, this field is merely for informational purposes - the amount raised can be more
          or less than that.
        </p>
      </div>
    ),
  },
  {
    question: 'Can a Syndicate Scale Back LPs if They Have Raised More Than the Set Allocation?',
    answer: (
      <div>
        <p>
          If the raised capital is more than what can be invested in the company, the Lead or a
          Founder (in the case of a RUV) can scale back{' '}
          <strong>limited partners ("LPs" or "Investors")</strong>.
        </p>
        <p className="mt-2">There are usually two ways Leads / Founders scale back LPs:</p>
        <ol className="list-decimal pl-5 mt-2">
          <li>All LPs proportionally</li>
          <li>Reducing select LPs' allocations</li>
        </ol>
      </div>
    ),
  },
  {
    question: 'What is the Maximum Number of Backers a Lead or Founder Can Invite?',
    answer: 'No syndicate can have more than 199 eligible Angel Investors (including the Lead).',
  },
  {
    question: 'Can a Deal be Shared Publicly?',
    answer: (
      <div>
        <p>Sharing deals publicly is not permitted.</p>
        <p className="mt-2">
          The Lead or a Founder (in the case of a RUV) cannot share deals on public platforms such
          as:
        </p>
        <ul className="list-disc pl-5 mt-2">
          <li>Twitter</li>
          <li>LinkedIn</li>
          <li>Newsletters</li>
          <li>Blogs</li>
        </ul>
      </div>
    ),
  },
  {
    question:
      'What are the Tax Implications of Investing Through AngelList India\'s SEBI-regulated "Angel Fund"?',
    answer: (
      <div>
        <p>
          When investing, the investor becomes part of AngelList India, registered with SEBI as an
          Angel Fund under the SEBI AIF Regulations, 2012.
        </p>
        <ul className="list-disc pl-5 mt-2">
          <li>Angel Funds have pass-through status for all incomes (except business income)</li>
          <li>
            Investors are taxed in the same nature and proportion as direct portfolio company
            investments
          </li>
          <li>10% withholding applied for resident investors</li>
          <li>Non-resident investors taxed at prevailing rates</li>
          <li>Investors can claim tax credit when filing returns</li>
        </ul>
        <p className="mt-2 italic text-sm">
          Important Note: AngelList India is not qualified to provide tax advice. Many exceptions
          exist to these general provisions. Investors should consult their own counsel, accountant,
          or investment advisor regarding legal and tax matters.
        </p>
      </div>
    ),
  },
  {
    question: 'Can the Terms of the Deal Change After the SPV Has Been Taken Live?',
    answer: (
      <div>
        <p>
          The Lead or Founder must inform the AngelList India operations manager of any deal term
          changes.
        </p>
        <p className="mt-2">Changing terms may require:</p>
        <ul className="list-disc pl-5 mt-2">
          <li>Notifying Limited Partners (LPs)</li>
          <li>Providing an opt-out option</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What is the Process for Reviewing and Signing Deal Documents?',
    answer: (
      <div>
        <p>
          Investee companies must share investment documents with the AngelList India team for
          review and vetting.
        </p>
        <ul className="list-disc pl-5 mt-2">
          <li>Legal team review takes 5-6 working days</li>
          <li>Feedback will be provided if necessary</li>
        </ul>
        <p className="mt-2">Additional Regulatory Details:</p>
        <ul className="list-disc pl-5">
          <li>Investments by "AL Trust" are considered "Indirect Foreign Investments"</li>
          <li>
            AngelList India's Investment Manager is a Foreign Owned and Controlled (FOCC) subsidiary
          </li>
          <li>Pricing guidelines and sectoral caps will apply</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'When Will AngelList India Wire the Funds to the Startup?',
    answer: (
      <div>
        <p>Funds will be wired after:</p>
        <ul className="list-disc pl-5 mt-2">
          <li>Investment documents are signed</li>
          <li>Other documentation is completed</li>
          <li>Funds from Limited Partners (LPs) are collected</li>
        </ul>
        <p className="mt-2 font-bold">
          Important: AngelList India does not allow investment in tranches.
        </p>
      </div>
    ),
  },
];

const investInSpvFaqs = [
  {
    question: 'What is the Minimum Amount to be Invested per Deal?',
    answer: (
      <div>
        <ul className="list-disc pl-5">
          <li>
            Domestic Indian bank accounts:
            <ul className="list-disc pl-5">
              <li>Minimum investment: INR 3 Lakhs per commitment</li>
              <li>
                Syndicate Leads get 5 exceptions where Investors can invest:
                <ul className="list-disc pl-5">
                  <li>Minimum: INR 1 Lakh</li>
                  <li>Maximum: Less than INR 3 Lakhs</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>International Investors: Minimum investment of INR 10 Lakhs per commitment</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What is the Minimum Amount to be Invested by LPs Through AngelList India?',
    answer: (
      <div>
        <p>As per SEBI guidelines, Investors must meet the following investment threshold:</p>
        <ul className="list-disc pl-5 mt-2">
          <li>Minimum of INR 25 Lakhs over 5 years from first investment</li>
          <li>Can be invested in a single deal or multiple deals</li>
        </ul>
        <p className="mt-2 italic">
          Recommendation: Make multiple investments to diversify startup investment risks.
        </p>
      </div>
    ),
  },
  {
    question: 'What are the KYC Requirements?',
    answer: (
      <div>
        <p>KYC must be completed during sign-up or first investment. Required documents:</p>
        <h3 className="mt-2 font-semibold">For Individuals:</h3>
        <ul className="list-disc pl-5">
          <li>
            Government ID (choose one):
            <ul className="list-disc pl-5">
              <li>PAN Card</li>
              <li>Aadhar Card</li>
              <li>Passport</li>
            </ul>
          </li>
          <li>
            Address Proof:
            <ul className="list-disc pl-5">
              <li>Latest utility bill</li>
              <li>Aadhar Card</li>
            </ul>
          </li>
        </ul>
        <h3 className="mt-2 font-semibold">For Body Corporates (Pvt Ltd / LLP):</h3>
        <ol className="list-decimal pl-5">
          <li>Certificate of incorporation</li>
          <li>Board resolution</li>
          <li>PAN copy</li>
          <li>Memorandum of Association</li>
          <li>Article of Association</li>
          <li>Latest share holding pattern</li>
          <li>Audited financials for 2 years</li>
          <li>Authorised signatory list</li>
        </ol>
      </div>
    ),
  },
  {
    question: 'How Does an Investor Commit to a Deal?',
    answer: (
      <div>
        <p>Deal Commitment Process:</p>
        <ol className="list-decimal pl-5">
          <li>Paste invite link shared by Leads/Founders in browser</li>
          <li>Login/Sign Up on AngelList India platform</li>
          <li>View Deal Page with investee company information</li>
          <li>
            Complete KYC:
            <ul className="list-disc pl-5">
              <li>Name</li>
              <li>Address</li>
              <li>Phone Number</li>
              <li>PAN Card Number</li>
              <li>Photo ID</li>
              <li>Address Proof</li>
            </ul>
          </li>
          <li>Review Terms & Conditions</li>
          <li>Confirm Investment</li>
        </ol>
        <p className="mt-2 font-bold">Note: No amount is required at deal commitment stage.</p>
      </div>
    ),
  },
  {
    question: 'When are Investors Required to Wire Their Investment Amount?',
    answer: (
      <div>
        <p>Funds are wired only after:</p>
        <ul className="list-disc pl-5">
          <li>All Investors in the scheme have committed</li>
          <li>Lead or Founder decides to close the scheme</li>
          <li>Target allocation is met</li>
        </ul>
        <p className="mt-2">AngelList India will send a "AL Trust - Drawdown Notice" email that:</p>
        <ul className="list-disc pl-5">
          <li>Confirms final investment amount</li>
          <li>Provides wiring instructions</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'How can an LP discover or participate in deals on AngelList India’s platform?',
    answer: (
      <div>
        <p>
          Deals on AngelList India are private and only visible to the LPs invited by Leads or
          Founders (in the case of a RUV). Here’s how it works:
        </p>
        <ul className="list-disc pl-5">
          <li>Leads or Founders can invite LPs from their syndicate dashboard.</li>
          <li>
            Accredited investors outside the AngelList India LP network can join via a private deal
            invite link.
          </li>
          <li>
            Leads usually fill deals through their investor networks. If additional allocation
            remains, AngelList India may review and share the deal on a deal-by-deal basis.
          </li>
          <li>In RUVs, Founders must source 100% of the capital from accredited investors.</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'How much does an Investor have to wire?',
    answer: (
      <div>
        <p>Investors must wire their committed investment amount plus a 2% setup fee. Details:</p>
        <ul className="list-disc pl-5">
          <li>
            For example, a commitment of INR 3 Lacs will require a transfer of INR 3.06 Lacs (INR 3
            Lacs + 2% setup fee of INR 6,000).
          </li>
          <li>RUVs are 0% carry vehicles, but investors must pay carry fees on Syndicate deals.</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'How do LPs wire their investment amount?',
    answer: (
      <div>
        <p>LPs can wire their investment amount using NEFT or RTGS. Important points:</p>
        <ul className="list-disc pl-5">
          <li>Wire instructions are provided in the Drawdown Notice.</li>
          <li>Cheque payments are not accepted.</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Which bank account can be used to wire the funds?',
    answer: (
      <div>
        <p>Investors must use bank accounts linked to their demat accounts. Requirements:</p>
        <ul className="list-disc pl-5">
          <li>
            The bank account name must match the investment entity used for the deal commitment.
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Can the funds be wired in 2 tranches?',
    answer: (
      <div>
        <p>Yes, funds can be wired in tranches if the bank has daily transfer limits. Notes:</p>
        <ul className="list-disc pl-5">
          <li>Confirmation is received only after all funds are wired.</li>
          <li>Domestic wires take up to 2 business days to reflect on the dashboard.</li>
          <li>International wires can take 5-10 business days.</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What should be done if any of the investment terms disclosed to LPs change?',
    answer: (
      <div>
        <p>
          If there are changes to the investment terms disclosed to LPs, the AngelList team must be
          notified immediately. Key points:
        </p>
        <ul className="list-disc pl-5">
          <li>
            Some changes may be immaterial, but material changes require LP notification and
            potentially an opt-out option.
          </li>
          <li>Examples of changes requiring LP notification and opt-out include:</li>
          <ul className="list-disc pl-10">
            <li>Valuation increases</li>
            <li>Discount rate drops</li>
            <li>Lead investor changes</li>
            <li>Loss of pro-rata rights</li>
          </ul>
        </ul>
      </div>
    ),
  },
  {
    question: 'When will the LPs receive closing documents?',
    answer: (
      <div>
        <p>
          LPs receive closing documents on their dashboards within a month of the investment
          closing. These documents include:
        </p>
        <ul className="list-disc pl-5">
          <li>
            A scheme information document outlining the terms of AngelList India’s investment and
            evidencing the LPs’ investment.
          </li>
          <li>
            Unit statements provided on 31st March and 30th September each year, which act as a
            statement of accounts.
          </li>
          <li>
            The unit statement also provides LPs with the Fair Market Value (FMV) of all their
            investments.
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Are LPs party to the investment documents of the investee company?',
    answer: (
      <div>
        <p>
          LPs in the scheme are not party to the investee company's investment documents (such as
          SSA, SHA, or Convertible Note Agreement). Instead:
        </p>
        <ul className="list-disc pl-5">
          <li>
            LPs subscribe to the scheme via a contribution agreement with AL Trust and AngelList
            India.
          </li>
          <li>
            AngelList India (the official signatory) reviews investment documents to ensure
            alignment with terms described to LPs before signing.
          </li>
          <li>
            If there is a divergence in terms, the Founder/Lead must post an update to LPs and offer
            an opportunity to opt-out (or in some cases, explicitly opt-in).
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Do all LPs invest on homogenous terms?',
    answer: (
      <div>
        <p>
          Yes, all Investors in the scheme invest on the same round terms as other Investors in the
          scheme.
        </p>
      </div>
    ),
  },
];

const ruvFaqs = [
  {
    question: 'What are the RUV size and configuration requirements?',
    answer: (
      <div>
        <p>The size and configuration of RUVs include the following parameters:</p>
        <ul className="list-disc pl-5">
          <li>Founders must source 100% of the capital from accredited investors.</li>
          <li>The minimum allocation for an RUV in a fundraising round is INR 50 Lacs.</li>
          <li>The maximum investment an RUV can make into a company is INR 10 Crores.</li>
          <li>
            The average minimum cheque size per investor is INR 3 Lacs, with up to 5 exceptions
            allowed for angels contributing between INR 1 and 3 Lacs.
          </li>
          <li>
            RUVs are 0% carry vehicles, meaning no carry fee is charged during a profitable exit.
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What companies are supported by RUVs?',
    answer: (
      <div>
        <p>RUVs can support private companies that meet these criteria:</p>
        <ul className="list-disc pl-5">
          <li>Qualify as a 'startup' per DPIIT’s definition.</li>
          <li>Are incorporated in India within the last 10 years.</li>
          <li>Have a turnover of less than INR 100 Crore.</li>
          <li>
            Are not promoted, sponsored by, or related to an Industrial Group with a turnover
            exceeding INR 300 Crores.
          </li>
          <li>Have no family connection with the founders or LPs participating in the RUV.</li>
          <li>
            Engage in manufacturing or services (excluding activities prohibited under SEBI (AIF)
            Regulations, 2012).
          </li>
          <li>
            Allow up to 100% foreign investment under the automatic route according to current
            Indian forex laws.
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Who can invest through an RUV?',
    answer: (
      <div>
        <p>Only 'eligible angel investors' can invest through RUVs. Criteria include:</p>
        <ul className="list-disc pl-5">
          <li>For individual investors:</li>
          <ul className="list-disc pl-10">
            <li>Net tangible assets of at least INR 2 Crores (excluding principal residence).</li>
            <li>
              Early-stage investment experience, serial entrepreneurship, or 10+ years as a senior
              management professional.
            </li>
          </ul>
          <li>For a body corporate (LLP, Company): Net worth of at least INR 10 Crores.</li>
          <li>
            For an AIF: Registration under SEBI AIF Regulations, 2012, or Venture Capital Fund (VCF)
            under SEBI (VCF) Regulations, 1996.
          </li>
          <li>
            Investors cannot invest via joint accounts, partnerships, proprietorships, or trust
            entities.
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Can NRIs invest in an RUV?',
    answer: (
      <div>
        <p>Yes, NRIs can invest in RUVs under these conditions:</p>
        <ul className="list-disc pl-5">
          <li>
            New non-resident syndicate investors: Minimum investment is INR 10 Lacs per deal, with
            an additional undertaking for compliance with Regulation 19D(3) of SEBI AIF regulations.
          </li>
          <li>
            Existing non-resident syndicate investors: The new INR 10 Lacs minimum per deal applies
            by default (previously INR 25 Lacs).
          </li>
        </ul>
        <p>This change aims to accommodate investors seeking increased India exposure.</p>
      </div>
    ),
  },
  {
    question: 'What is the maximum number of investors in an RUV?',
    answer: (
      <div>
        <p>The maximum number of investors allowed in an RUV is 199.</p>
      </div>
    ),
  },
  {
    question: 'Do LPs need a separate AngelList profile?',
    answer: (
      <div>
        <p>Yes, LPs must register on AngelList India if they haven’t already.</p>
      </div>
    ),
  },
  {
    question: 'How can LPs commit to a deal?',
    answer: (
      <div>
        <p>LPs can commit to a deal by being invited through a private link. Key points:</p>
        <ul className="list-disc pl-5">
          <li>
            The link directs them to the RUV investment page and can be generated from the
            dashboard.
          </li>
          <li>
            These links must not be used for general solicitation (e.g., posting on Twitter or
            including in press releases).
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Is the RUV accessible to Investors browsing AngelList?',
    answer: (
      <div>
        <p>No, the RUV is not discoverable on AngelList. Details:</p>
        <ul className="list-disc pl-5">
          <li>Only individuals invited via private links can view the RUV.</li>
          <li>All capital must be raised through the founder's private network.</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Who controls the closing date for the RUV?',
    answer: (
      <div>
        <p>The founder controls the closing date for the RUV. Key considerations:</p>
        <ul className="list-disc pl-5">
          <li>The closing deadline can be edited at any time.</li>
          <li>Email notifications are sent as the RUV approaches its closing date.</li>
          <li>The RUV can be re-opened before the investment is made in the company.</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Can LPs see how much has been raised or who else has invested in the RUV?',
    answer: (
      <div>
        <p>
          No, LPs cannot see the amount committed or the identities of other investors while the RUV
          is actively raising.
        </p>
      </div>
    ),
  },
  {
    question: 'Do investment documents need to be shared ahead of time?',
    answer: (
      <div>
        <p>No, investment documents do not need to be shared ahead of time. Key details:</p>
        <ul className="list-disc pl-5">
          <li>AngelList only requires high-level investment terms initially.</li>
          <li>Investment documents will be reviewed and signed when the RUV is closing.</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Are Investors in a RUV signing the investment documents when they commit?',
    answer: (
      <div>
        <p>
          No, Investors in a RUV do not sign the investment documents when they commit. Instead:
        </p>
        <ul className="list-disc pl-5">
          <li>They electronically sign fund documents that subscribe them to an SPV.</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What should be done if any of the investment terms disclosed to LPs change?',
    answer: (
      <div>
        <p>If there are changes to the investment terms disclosed to LPs, follow these steps:</p>
        <ul className="list-disc pl-5">
          <li>Notify the AngelList team immediately.</li>
          <li>
            Some changes may not be material, but material changes require LP notification and may
            offer them an opt-out option.
          </li>
          <li>Examples of changes requiring notification and opt-out include:</li>
          <ul className="list-disc pl-10">
            <li>Valuation increases</li>
            <li>Discount rate drops</li>
            <li>Investor changes</li>
            <li>Loss of pro-rata rights</li>
          </ul>
        </ul>
      </div>
    ),
  },
  {
    question: 'Can the amount raised in an RUV exceed the target allocation specified?',
    answer: (
      <div>
        <p>
          Yes, the amount raised in an RUV can exceed or be less than the target allocation
          specified. Note:
        </p>
        <ul className="list-disc pl-5">
          <li>The target allocation field is for informational purposes only.</li>
          <li>It does not limit the actual amount of capital that can be raised.</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What should be done if an LP requests wire instructions or a wire callback?',
    answer: (
      <div>
        <p>If an LP requests wire instructions or a wire callback:</p>
        <ul className="list-disc pl-5">
          <li>
            Introduce them to the AngelList India team via email at{' '}
            <a href="mailto:hi@angellistindia.com">hi@angellistindia.com</a>.
          </li>
          <li>Include "{'<Company Name> RUV'}" in the email subject or body for clarity.</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'Who provides the signature block for the RUV?',
    answer: (
      <div>
        <p>AngelList provides the signature block, which includes:</p>
        <ul className="list-disc pl-5">
          <li>The legal name of the RUV</li>
          <li>Its address</li>
          <li>Signatory information</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What happens when the founder is ready to close the RUV?',
    answer: (
      <div>
        <p>When the founder is ready to close the RUV, the process includes:</p>
        <ul className="list-disc pl-5">
          <li>AngelList reviews and signs the investment documents with the company.</li>
          <li>
            The team ensures terms in the documents match those disclosed to LPs on the deal page.
          </li>
          <li>If signing needs to be delayed, founders should notify AngelList.</li>
          <li>All capital must be received from RUV LPs before signing.</li>
        </ul>
        <p>
          LPs who have completed their investments appear in the "Completed" column on the
          fundraising dashboard.
        </p>
      </div>
    ),
  },
  {
    question: 'When does AngelList wire the investment amount to the company?',
    answer: (
      <div>
        <p>
          AngelList wires the investment amount to the company once all funds from LPs have been
          received.
        </p>
      </div>
    ),
  },
  {
    question: 'What happens after the RUV wire is completed?',
    answer: (
      <div>
        <p>Once the RUV wire is completed:</p>
        <ul className="list-disc pl-5">
          <li>The funds are transferred to the startup, and the RUV is finalized.</li>
          <li>New investor commitments are no longer accepted.</li>
          <li>
            If additional funds are needed at different terms, a new (discounted) RUV can be created
            for future rounds.
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'How does AngelList handle post-close corporate actions?',
    answer: (
      <div>
        <p>For post-close corporate actions:</p>
        <ul className="list-disc pl-5">
          <li>AngelList will sign on behalf of the fund if necessary.</li>
          <li>A contact address will be provided for the legal team to request signatures.</li>
          <li>
            AngelList’s legal team reviews each "post-close action" to assess its impact on the
            SPV's rights.
          </li>
          <li>This review takes 2 full business days after receiving all required documents.</li>
          <li>
            If a proxy agreement is signed, founders may vote shares for routine corporate actions
            without needing AngelList's involvement.
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'How can Investors access their executed fund documents and investment certificates?',
    answer: (
      <div>
        <p>
          Investors can access their executed fund documents and investment certificates through
          their dashboard. Details:
        </p>
        <ul className="list-disc pl-5">
          <li>Access becomes available once the RUV is finalized.</li>
          <li>
            Typically, finalization occurs one to two weeks after the investment in the company has
            been made.
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What happens during exit scenarios for RUV Investors?',
    answer: (
      <div>
        <p>
          During an exit opportunity (e.g., secondary sale, acquisition, or qualified liquidation
          event):
        </p>
        <ul className="list-disc pl-5">
          <li>RUV Investors are notified of the details through their RUV Dashboard.</li>
          <li>
            AngelList India manages exit calculations, distributions, and compliance for Investors.
          </li>
          <li>For exit scenarios requiring explicit Investor approval:</li>
          <ul className="list-disc pl-10">
            <li>
              AngelList India, as the investment manager, seeks consent from each Investor via the
              platform.
            </li>
            <li>Approval is based on a simple majority {`(>50%)`}.</li>
            <li>
              Each Investor’s voting power is proportional to the number of units they hold in the
              specific scheme or investment.
            </li>
          </ul>
        </ul>
      </div>
    ),
  },
];

const communityGuidelines = {
  question: 'What guidelines must Lead Angel Investors abide by when running SPVs?',
  answer: (
    <div>
      <p>
        The AngelList India Platform provides a secure and efficient environment for Lead Angel
        Investors and AngelList India LPs to fundraise and make investments. To run your Syndicate
        on the AngelList India Platform, you must agree to the following guidelines:
      </p>

      <ul className="list-disc pl-5 space-y-3 my-4">
        <li>
          <strong className="">Deal Information Accuracy</strong>
          <ul className="list-disc pl-5">
            <li>
              I will submit deals only where I have confirmed participation in the round from the
              investee company.
            </li>
            <li>
              I will represent deal information accurately to the best of my knowledge.{' '}
              <em>Note: violations may result in termination of the deal.</em>
            </li>
            <li>
              I will avoid overly promissory statements about the investee company’s prospects in
              the Deal Memo.
            </li>
            <li>
              I will list co-investors on the Deal Page only if they are investing on the same terms
              in the same timeframe as my SPV and I have permission to disclose their identities.
            </li>
            <li>I will clearly articulate all major deal-related risks to LPs on the Deal Page.</li>
            <li>
              I understand that the continued accuracy of deal information is my responsibility.
            </li>
          </ul>
        </li>

        <li>
          <strong>Abiding by AngelList India's Terms</strong>
          <ul className="list-disc pl-5">
            <li>
              I will not prompt LPs to leave the AngelList India Platform and participate in
              off-platform deals.
            </li>
            <li>
              I understand that AngelList India charges carry on capital sourced on and through the
              AngelList India Platform for all deals.
            </li>
            <li>
              I will not actively solicit personal information from LPs via the Platform, including,
              but not limited to, email and mailing addresses.
            </li>
          </ul>
        </li>

        <li>
          <strong>Funding Timeliness</strong>
          <ul className="list-disc pl-5">
            <li>I will set clear expectations with my LPs on funding timelines and deadlines.</li>
            <li>
              I understand that wiring to investee companies is contingent on receiving funds from
              LPs within the specified time frame.
            </li>
          </ul>
        </li>

        <li>
          <strong>Communication</strong>
          <ul className="list-disc pl-5">
            <li>I will not spam LPs with Posts about deal information.</li>
            <li>I will immediately make AngelList India and LPs aware if deal terms change.</li>
            <li>
              I understand that if material deal terms change, I may need to provide the opportunity
              for LPs to opt out of the deal.
            </li>
          </ul>
        </li>
      </ul>

      <p>
        I/We understand that violating the terms of the AngelList India Community Guidelines may
        result in AngelList India taking actions such as restricting my ability to invite AngelList
        India LPs to my SPV, accepting capital on the platform from such LPs, removing AngelList
        India LPs from my SPV, cancelling my SPV, or, in extreme cases, terminating my use of the
        AngelList India Platform. These guidelines supplement our Terms of Service, which prevail in
        the event of any inconsistency with these guidelines.
      </p>
    </div>
  ),
};

export { generalFaqs, investInSpvFaqs, ruvFaqs, communityGuidelines };
